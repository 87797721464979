import React from 'react'
import Link from 'gatsby-link'
import './style.css'
import bg1 from '../../assets/images/resource-bg-1.png'
import bg2 from '../../assets/images/bg-2.png'
// import Timeline from 'react-timeline-semantic-ui';

const ResearchIndex = () => (
  <div className='page-wrapper research-home'>
    <img src={bg1} className="bg-1" />
    <img src={bg2} className="bg-2" />
    <div className="research-links">
      {/* <h1>Additional Research and Resources</h1> */}
      
      <div className="research-top-left section-content">
        <h3>Research</h3>
        <Link to="/scanning-hits/"><div className="toc-number">2.1</div> Scanning Hits</Link>
        <Link to="trends/"><div className="toc-number">2.2</div> Trends</Link>
        <Link to="/drivers/"><div className="toc-number">2.3</div> Drivers</Link>
      </div>
      
      <div className="research-bottom-right section-content">
        <h3>Interviews</h3>
        <Link to="/research/interviews/">
            <div className="interview-index-link-title"><div className="toc-number">2.4</div> Death in the Emergency Room</div>
        </Link>
      </div>
    
      <div className="research-next-outer">
        <h2>WHEN YOU'RE DONE HERE...</h2>
          <ul className="research-nav-ul">
            <li><Link to="/scenarios">Scenarios</Link></li>
            <li><Link to="/table-of-contents">Home</Link></li>
          </ul>
      </div>
    </div>
  </div>
)

export default ResearchIndex
